import { inject } from "@angular/core";
import { CanActivateChildFn, CanActivateFn, Router, UrlTree } from "@angular/router";

import { AdaaHelper } from "../utils";

/**
 * Guard to check if user is logged in
 * If not, redirect to login page
 *
 * @usageNotes
 *  - for authenticated routes
 *      canActivate: [authGuard('logged-in')]
 *  - for unauthenticated routes
 *      canActivate: [authGuard('not-logged-in')]
 */
export function authGuard(checkIf: "logged-in" | "not-logged-in"): CanActivateFn | CanActivateChildFn {
  return (): boolean | UrlTree => {
    const router: Router = inject(Router);
    switch (checkIf) {
      case "logged-in":
        return AdaaHelper.isLoggedIn() ? true : router.createUrlTree(["/login"]);
      case "not-logged-in":
        return !AdaaHelper.isLoggedIn() ? true : router.createUrlTree(["/user-profile"]);
    }
  };
}

export const checkIfLoggedInFn = authGuard("logged-in");
export const checkIfNotLoggedInFn = authGuard("not-logged-in");
