import { inject } from "@angular/core";
import { CanActivateChildFn, CanActivateFn, Router } from "@angular/router";

import { Constants } from "../../shared/constants/constants";
import { AdaaHelper } from "../utils";

export function NotArchivedPlan(): CanActivateFn | CanActivateChildFn {
  return () => {
    const router = inject(Router);

    const planStatus =
      AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, { type: "prop", property: "status" }) ?? 0;

    return planStatus !== Constants.OBJECT_STATUS.ARCHIVED && planStatus !== Constants.OBJECT_STATUS.CLOSED
      ? true
      : router.createUrlTree(["/403"]);
  };
}
