import { inject } from "@angular/core";
import { CanActivateFn, Router, UrlTree } from "@angular/router";

import { AdaaHelper } from "../utils";

function entityTypeGuard(isPMO: boolean): CanActivateFn {
  return (): boolean | UrlTree => {
    const router = inject(Router);

    if (isPMO) return AdaaHelper.isPMOEntity() ? true : router.createUrlTree(["/403"]);
    else return !AdaaHelper.isPMOEntity() ? true : router.createUrlTree(["/403"]);
  };
}

export const pmoEntity = entityTypeGuard(true);
export const notPmoEntity = entityTypeGuard(false);
