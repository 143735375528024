import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, map, of } from "rxjs";

import { PermissionActionDescriptorType } from "../../../adaa-types";
import { PermissionsService } from "../../shared/services";

export const handleRoutePermission = (data: PermissionActionDescriptorType) => {
  const router = inject(Router);
  const permissionsService = inject(PermissionsService);
  const { actor, redirectTo } = data;
  const hasPermissionsLoaded = permissionsService.hasPermissionsLoaded();
  if (hasPermissionsLoaded) {
    const hasPermission = permissionsService.hasPermission(actor.permissions, actor.modifier);
    return hasPermission ? true : router.createUrlTree([redirectTo ?? "/403"]);
  }
  return permissionsService.loadUserPermissions().pipe(
    map(() => {
      const hasPermission = permissionsService.hasPermission(actor.permissions, actor.modifier);
      return hasPermission ? true : router.createUrlTree([redirectTo ?? "/403"]);
    }),
    catchError((e) => {
      // eslint-disable-next-line no-console
      console.warn("Error while loading permissions", e);
      const routeTree = router.createUrlTree([redirectTo ?? "/403"]);
      return of(routeTree);
    })
  );
};
