import { inject } from "@angular/core";
import { CanActivateChildFn, CanActivateFn } from "@angular/router";

import { ResourcesApiService } from "../../shared/services";

export function PingGuard(): CanActivateFn | CanActivateChildFn {
  return () => {
    //Just to ping the server to keep the session alive
    //If session is expired, the logout will be handled by the error interceptor
    const resourcesApiService = inject(ResourcesApiService);
    resourcesApiService.ping().subscribe();

    return true;
  };
}
